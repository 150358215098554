import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout pageTitle={frontmatter.title}>
      <Link to="/texter">
        <div className='flex-none inline-block mb-4 px-4 py-3 rounded-3xl bg-gray-200 hover:bg-gray-100'><FontAwesomeIcon icon={faChevronLeft}/> Tillbaka</div>
      </Link>
      <div className='max-w-lg'>
        <h1 className="mb-2 text-lg font-bold border-b-2 border-blue-400">{frontmatter.title}</h1>
        <article
          className="text_page"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`